.userMenuCallout {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 16px;
  min-width: 160px;
  max-width: 320px;

  // Light Mode (Matches Image)
  [data-theme="light"] & {
    background-color: #FFFFFF !important;
    color: #000000 !important;
  }

  // Dark Mode (Matches Image)
  [data-theme="dark"] & {
    background-color: #1C1C1E !important;
    color: #A1A1A1 !important;
  }
}

.userMenuTitle {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;

  [data-theme="light"] & {
    color: #000000 !important;
  }

  [data-theme="dark"] & {
    color: #FFFFFF !important;
  }
}

.userMenuLabel {
  display: block;
  font-size: 14px;
  margin-bottom: 6px;

  [data-theme="light"] & {
    color: #000000 !important;
  }

  [data-theme="dark"] & {
    color: #A1A1A1 !important;
  }
}

.stackItem {
  padding: 5px 0;
}

.userMenuDropdown{
  min-height: 35px;
}

.userMenuSpinner {
  display: flex;
  justify-content: center;
  min-height: 35px;
}

.userMenuLogout {
  margin-top: 16px;

  &:hover {
    opacity: 0.9;
  }
}
