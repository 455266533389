@import "./../../../../Variables.scss";

.iframe {
  border: 0px;
  width: 100%;
  height: 100%;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
